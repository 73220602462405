<template>
    <div class="hiq-meeting-planner__hit-list-container">
        <ul class="hiq-meeting-planner__hit-list" id="meeting-planner-hit-list" aria-live="polite">
            <template v-for="(hit, indx) in hits" >
                <search-hit :hit="hit" :key="'hit-' + indx" />
                <hr :key="'hit-' + indx">
            </template>
        </ul>
        <div class="hiq-meeting-planner__load-more-container" v-if="showLoadMoreButton">
            <button v-on:click="loadMore" class="hiq-meeting-planner__load-more hiq-button hiq-button--full-width hiq-button hiq-button--orange hiq-button--plus">{{translations.viewmoree}}</button>
        </div>
    </div>
</template>
<script>
import SearchHit from './SearchHit'
export default {
    name: 'SearchHitList',
    props: {
        translations: {}
    },
    components: {
        SearchHit
    },
    computed: {
        hits(){
            return this.$store.state.search_hits;
        },
        currentPage(){
            return this.$store.getters.current_page;
        },
        showLoadMoreButton(){
            console.log("this.currentPage", this.currentPage);
            console.log("this.$store.getters.total_pages", this.$store.getters.total_pages);
            return this.$store.getters.current_page < this.$store.getters.total_pages;
        }
    },
    methods: {
        loadMore: function(){
            console.log("this.currentPage", this.currentPage);
            console.log("this.$store.getters.total_pages", this.$store.getters.total_pages);

            this.$store.commit({
                type: 'setCurrentPage',
                payload: (parseInt(this.currentPage) + 1)
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        }
    }
}
</script>