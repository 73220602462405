import axios from 'axios';
import queryString from 'query-string';
import { IState, IFilters } from '../src/store/state';


const BASE_URL = '';
const dev = process.env.NODE_ENV === 'development';

const SiteVisionHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
}

interface UserInformationResponse {
    message: string,
    status: number
}

const makeSearchParams = (state: any) => {
    var searchFilters: any = {};

    var filters = state.filters;

    var dataObject = {
        page: state.current_page,
        //filters: JSON.stringify(state.filters)
        filters: JSON.stringify(filters)
    };

    return dataObject;
}

export const search = async (url: string, state: IState, optionalOverrides: any = ""): Promise<UserInformationResponse> => {
    const params = Object.assign({}, makeSearchParams(state), optionalOverrides);
    const oldParams = queryString.parse(url);

    const route = `?${queryString.stringify({
        ...oldParams,
        ...params
    })}`;

    return axios.get<UserInformationResponse>(route, { headers: { ...SiteVisionHeaders } })
        .then((response: any) => response.data)
        .catch((error: any) => {
            console.error({ error });
            return null;
        })

}