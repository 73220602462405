import { Getter } from "vuex";
import { IState } from './state';

const search_hits: Getter<IState, IState> = state => state.search_hits;

const current_page: Getter<IState, IState> = state => state.current_page;
const total_pages: Getter<IState, IState> = state => state.total_pages;
const translations: Getter<IState, IState> = (state) => state.translations;

const urlQuery: Getter<IState, IState> = state => {
	var searchFilters = {};
	var filters = state.filters;

	console.log(state);

	
	// if(filters){
	// 	if(filters['meetingRoomSize'])
	// 		searchFilters = filters.meetingRoomSize;
	// 	if(filters['groupRooms'])
	// 		searchFilters += "," + filters.groupRooms;
	// 	if(filters['dinner'])
	// 		searchFilters += "," + filters.dinner;
	// 	if(filters['hotelRooms'])
	// 		searchFilters += "," + filters.hotelRooms;
	// 	if(filters['trainstationDistance'])
	// 		searchFilters += "," + filters.trainstationDistance;
			
	// 	console.log(searchFilters);
	// }

	var basic = { page: state.current_page }
	console.log("basic", basic);

	var queryObject = { page: state.current_page, ...filters };
	console.log("queryObject", queryObject);

	return queryObject;
};
export default {
	search_hits,
	current_page,
	total_pages,
	urlQuery,
	translations
}
