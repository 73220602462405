export interface IHit {
	title: string,
	meetingRoomSize: number,
	groupRooms: number,
	dinner: number,
	hotelRooms: number,
	trainstationDistance: number,
	url: string
  }
  export interface IFilters {
	meetingRoomSize: number,
	groupRooms: number,
	dinner: number,
	hotelRooms: number,
	trainstationDistance: number
  }
  
  export interface IState {
	search_hits: Array<IHit>;
	total_hits: number;
	total_pages: number;
	current_page: number;
	filters: IFilters;
	show_filter: Boolean;
	app: {
		hits_per_page: number;
		submit_url: string;
	};
	translations: object
  }
  
  const deafultState: IState = {
	search_hits: [],
	total_hits: 0,
	total_pages: 1,
	current_page: 1,
	show_filter: false,
	filters: {
		meetingRoomSize: 0,
		groupRooms: 0,
		dinner: 0,
		hotelRooms: 0,
		trainstationDistance: 0
	},
	app: {
		hits_per_page: 9,
		submit_url: ""
	},
	translations: {}
  };
  
  export default deafultState;
  