import { MutationTree, Mutation } from 'vuex';
import { IState } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setSearchHits: MutationHandler<any> = (state, { payload }) => {
	state.search_hits = payload;
}

const initFilters: MutationHandler<any> = (state, { payload }) => {
	state.filters = payload;
	state.show_filter = false;
}

const setCurrentPage: MutationHandler<any> = (state, { payload }) => {
	state.current_page = payload;
}	

const setTotalPages: MutationHandler<any> = (state, { payload }) => {
	state.total_pages = payload;
}	

const setFilters: MutationHandler<any> = (state, { payload }) => {
	state.filters = payload;
}

const setHitCount: MutationHandler<any> = (state, { payload }) => {
	state.total_hits = payload;
}	
const setShowFilters: MutationHandler<any> = (state, { payload }) => {
	state.show_filter = payload;
}
const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setFilters: setFilters,
	setCurrentPage: setCurrentPage,
	setTotalPages: setTotalPages,
	initFilters: initFilters,
	setSearchHits: setSearchHits,
	setHitCount: setHitCount,
	setShowFilters: setShowFilters
}

export default mutations
