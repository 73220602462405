










import Vue from 'vue'
import SearchFilters from "./components/SearchFilters.vue";
import SearchHitList from './components/SearchHitList.vue';


// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		hitCounts: function(){
            return this.$store.state.total_hits;
        },
		translations: function(){
			return this.$store.state.translations;
		}
	},
	components: {
		SearchFilters,
		SearchHitList
	}
})
