<template>
    <div style="background:#000000">
        <button class="hiq-meeting-planner__form-toggle-button hiq-button hiq-button--full-width hiq-button hiq-button--orange hiq-button--plus" v-on:click="toggleFilters" aria-controls="meeting-planner-form" :aria-expanded="showFilters">{{translations.filteryoursearch}}</button>
        <span class="hiq-meeting-planner__form-header hiq-h4--white">{{translations.filteryoursearch}}</span>
        <form id="meeting-planner-form" v-on:submit.prevent="submitForm" class="hiq-meeting-planner__form hiq-form" v-show="showFilters">
            <div class="hiq-meeting-planner__form-wrapper">
                <div class="hiq-meeting-planner__filter">
                    <label class="hiq-form__label hiq-meeting-planner__form-label" for="hiq-meeting-room-size">{{translations.meetingcapacity}} <span class="sv-visuallyhidden">{{translations.numberofparticipants_visuallyhidden}}</span></label>
                    <input type="number" min="0" class="hiq-form__input hiq-form__input--outlined" v-bind:placeholder="translations.numberofparticipants" v-on:input="submitForm" v-on:keyup.enter="submitForm" :id="'hiq-meeting-room-size'" :name="'hiq-meeting-room-size'" v-model="filters.meetingRoomSize">
                </div>      
                <div class="hiq-meeting-planner__filter">
                    <label class="hiq-form__label hiq-meeting-planner__form-label" for="hiq-meeting-room-group">{{translations.meetingrooms}} <span class="sv-visuallyhidden">{{translations.numberofrooms_visuallyhidden}}</span></label>
                    <input type="number" min="0" class="hiq-form__input hiq-form__input--outlined" v-bind:placeholder="translations.numberofrooms" v-on:input="submitForm" v-on:keyup.enter="submitForm" :id="'hiq-meeting-room-group'" :name="'hiq-meeting-room-group'" v-model="filters.groupRooms">
                </div>
                <div class="hiq-meeting-planner__filter">
                    <label class="hiq-form__label hiq-meeting-planner__form-label" for="hiq-meeting-room-dinner">{{translations.dinner}} <span class="sv-visuallyhidden">{{translations.numberofguests_visuallyhidden}}</span></label>
                    <input type="number" min="0" class="hiq-form__input hiq-form__input--outlined" v-bind:placeholder="translations.numberofguests" v-on:input="submitForm" v-on:keyup.enter="submitForm" :id="'hiq-meeting-room-dinner'" :name="'hiq-meeting-room-dinner'" v-model="filters.dinner">
                </div>
                <div class="hiq-meeting-planner__filter">
                    <label class="hiq-form__label hiq-meeting-planner__form-label" for="hiq-meeting-room-hotel">{{translations.accomodation}} <span class="sv-visuallyhidden">{{translations.numberofrooms_visuallyhidden}}</span></label>
                    <input type="number" min="0" class="hiq-form__input hiq-form__input--outlined" v-bind:placeholder="translations.numberofrooms" v-on:input="submitForm" v-on:keyup.enter="submitForm" :id="'hiq-meeting-room-hotel'" :name="'hiq-meeting-room-hotel'" v-model="filters.hotelRooms">
                </div>
                <div class="hiq-meeting-planner__filter">
                    <label class="hiq-form__label hiq-meeting-planner__form-label" for="hiq-meeting-room-distance">{{translations.maximumdistancefromthetrainstation}} <span class="sv-visuallyhidden">{{translations.numberofkilometers_visuallyhidden}}</span></label>
                    <input type="number" step="0.01" min="0" class="hiq-form__input hiq-form__input--outlined" v-bind:placeholder="translations.numberofkilometers" v-on:input="submitForm" v-on:keyup.enter="submitForm" :id="'hiq-meeting-room-distance'" :name="'hiq-meeting-room-distance'" v-model="filters.trainstationDistance">
                </div>
                <!-- <div class="hiq-meeting-planner__submit-button-container">
                    <button type="submit" class="hiq-button hiq-form__submit">SÖK</button>
                </div> -->
            </div>
            <div class="hiq-meeting-planner__form-clear-container">
                <button type="reset" class="hiq-meeting-planner__form-clear" v-on:click.prevent="clearForm">{{translations.clearfilter}}</button>
            </div>
        </form>
    </div>
</template>


<script>
export default ({
    name: 'searchFilters',
    props: {
        translations: {}
    },
	computed: {
        filters: function(){
            return this.$store.state.filters;
        },
        showFilters: {
            get () {
                return this.$store.state.show_filter;
            },
            set (value) {
                this.$store.commit({
                    type: 'setShowFilters',
                    payload: value
		        });
            }
        },
    },
    methods: {
        toggleFilters: function(){
            this.showFilters = !this.showFilters;
        },
        clearForm: function(){
            this.$store.commit({
                type: 'setFilters',
                payload: {}
            });
            this.submitForm();
        },
        submitForm: function(){
            this.$store.commit({
                type: 'setCurrentPage',
                payload: 1
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        }
    }
})
</script>
<style>

</style>