import { IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from "../../api";


type PayloadType = 'initApp' | 'setState' | 'setStateFromQuery' | 'newSearch'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	});
	commit({
		type: 'initFilters',
		payload: payload.filters
	});
}
// Action creators
export const setStateFromQuery: ActionCreator<any> = (state) => ({
	type: 'setStateFromQuery',
	payload: state
})

const setStateFromQueryAction: ActionHandler<any> = ({ commit }, { payload }) => {
	var page = payload.page;
	if(!page)
		page = 1;
	delete payload.query;
	var filters = {
		meetingRoomSize: payload.meetingRoomSize,
		groupRooms: payload.groupRooms,
		dinner: payload.dinner,
		hotelRooms: payload.hotelRooms,
		trainstationDistance: payload.trainstationDistance
	};

	if(page){
		commit({
			type: 'setCurrentPage',
			payload: parseInt(page)
		});
	}

	if (filters){
		commit({
			type: 'setFilters',
			payload: filters
		});
	}
}

export const newSearch: ActionCreator<IState> = (state) => ({
	type: 'newSearch',
	payload: state
})

const newSearchAction: ActionHandler<IState> = ({ commit, state }) => {
	// Make the search
	api.search(state.app.submit_url, state)
		.then((result: any) => {
			commit({
				type: 'setSearchHits',
				payload: result.search_hits
			});
			commit({
				type: 'setHitCount',
				payload: result.total_hits
			});		
			commit({
				type: 'setTotalPages',
				payload: result.total_pages
			});				
		})
		.catch(console.error);
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	setStateFromQuery: setStateFromQueryAction,
	newSearch: newSearchAction
}

export default actions;
