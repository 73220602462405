<template>
  <li class="hiq-meeting-planner__hit">
    <div class="hiq-meeting-planner__hit-link-container">
        <a :href="hit.url + '#Motesrubrik'" class="hiq-meeting-planner__hit-link">
          <p class="hiq-meeting-planner__hit-title hiq-text-arrow--orange">{{ hit.title }}</p>
          <ul class="hiq-meeting-planner__hit-info-list" v-if="hit.meetingRoomSize">
              <li class="hiq-meeting-planner__hit-info-container" v-if="hit.meetingRoomSize">
                  <span class="hiq-p--white hiq-meeting-planner__hit-info-header">{{translations.meetingcapacity}}:</span>
                  <span class="hiq-meeting-planner__hit-info">{{ hit.meetingRoomSize }} {{translations.persons}}</span>
              </li>
              <li class="hiq-meeting-planner__hit-info-container" v-if="hit.groupRooms">
                  <span class="hiq-p--white hiq-meeting-planner__hit-info-header">{{translations.meetingrooms}}:</span>
                  <span class="hiq-meeting-planner__hit-info">{{hit.groupRooms}} {{translations.rooms}}</span>
              </li>
              <li class="hiq-meeting-planner__hit-info-container" v-if="hit.dinner">
                  <span class="hiq-p--white hiq-meeting-planner__hit-info-header">{{translations.dinner}}:</span>
                  <span class="hiq-meeting-planner__hit-info">{{hit.dinner}} {{translations.guests}}</span>
              </li>
              <li class="hiq-meeting-planner__hit-info-container" v-if="hit.hotelRooms">
                  <span class="hiq-p--white hiq-meeting-planner__hit-info-header">{{translations.accomodation_alt}}:</span>
                  <span class="hiq-meeting-planner__hit-info">{{hit.hotelRooms}} {{translations.rooms}}</span>
              </li>         
              <li class="hiq-meeting-planner__hit-info-container" v-if="hit.trainstationDistance">
                  <span class="hiq-p--white hiq-meeting-planner__hit-info-header">{{translations.location}}:</span>
                  <span class="hiq-meeting-planner__hit-info">{{hit.trainstationDistance / 1000}} km {{translations.to}} Eskilstuna C</span>
              </li>
          </ul>
        </a>
      </div>
  </li>
</template>
<script>

export default {
  name: "SearchHit",
  mixins: [],
  props: {
    hit: Object
  },
  computed: {
    translations: function(){
			return this.$store.state.translations;
		}
  }
};
</script>