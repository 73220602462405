import Vue from 'vue';
import App from './App.vue';

import makeStore from './store';

import { initApp, setStateFromQuery, newSearch} from './store/actions';
import { IState } from './store/state';
import VueRouter from "vue-router";

Vue.use(VueRouter);

Vue.config.productionTip = false;

const createApp = (element: Element, data: IState) => {
	const store = makeStore(); // Make a new store for each instance.
	const router = new VueRouter({
		mode: 'history',
		routes: [
			{ path: '/' },
		]
	});

	const instance = new Vue({
		store,
		router,
		created() {
			this.$store.dispatch(initApp(data));
			if (this.$route.query) {
				this.$store.dispatch(setStateFromQuery(this.$route.query));
			}
			this.$store.dispatch('newSearch');

			router.beforeEach((to, from, next) => {
				this.$store.dispatch(setStateFromQuery(to.query));
				this.$store.dispatch('newSearch');
				next();
			});
		},
		render: h => h(App)
	}).$mount(element);

	return instance;
};

if (process.env.NODE_ENV === 'production') {
	(window as any).MEETING_PLANNER = (window as any).MEETING_PLANNER || createApp;
} else {
	const appElement = document.querySelector('#app');
	if(appElement) {
		const mock = require('./mock-state').default;
		createApp(appElement, mock);
	}
}
